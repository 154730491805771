import React from 'react'
import './index.less'
export default class Footer extends React.Component{
    render(){
        return(
            <div className="footer">
                版权所有：苏州悠优互娱文化传媒有限公司（推荐使用谷歌浏览器，可以获得更佳操作页面体验）
            </div>
        )
    }
}